import React, { useEffect, useState, useMemo } from "react";
import style from "./Performance.module.css";
import { useLocation } from "react-router-dom";
import { baseUrl } from "../Url";
import { Doughnut } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import dayjs from "dayjs";
import ActiveCalendar from "../../assets/activeCalendar.png";
import ActiveWorkOut from "../../assets/ActiveWeightlifting-sport-exercise-gym (1).png";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement, 
  LineElement,  
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement, // Import PointElement for Line charts
  LineElement,  
);

const Performance = () => {
  const location = useLocation();
  const userid = location.state?.userID;
  const orgid = location.state?.orgID;

  const key = JSON.parse(localStorage.getItem("access-token"));

  const [mainData, setMainData] = useState({});

  const sampleGraph = [
    { topic_no: 1, percentage: 75, mark: 12, lat_mark: 61 },
    { topic_no: 2, percentage: 55, mark: 9, lat_mark: 37 },
    { topic_no: 3, percentage: 89, mark: 14, lat_mark: 46 },
    { topic_no: 4, percentage: 45, mark: 8, lat_mark: 11 },
    { topic_no: 5, percentage: 25, mark: 5, lat_mark: 12 },
    { topic_no: 6, percentage: 45, mark: 8, lat_mark: 24 },
    { topic_no: 7, percentage: 25, mark: 5, lat_mark: 14 },
  ];
  const [selectedModule, setSelectedModule] = useState(0);
  const [selectedDate, setSelectedDate] = useState("W");

  const [dailyDrillData, setDailyDrillData] = useState([]);
  const [weeklyWorkoutData, setWeeklyWorkoutData] = useState([]);
  const [dateForDD, setDateForDD] = useState("week");
  const [dateForWW, setDateForWW] = useState("week");

  const handleDDDate = (date) => {
    setDateForDD(date);
    dailyDrillGraph(date);
  };

  const handleWWDate = (date) => {
    setDateForWW(date);
    weeklyWorkoutGraph(date);
  };

  const getColorBasedOnPercentage = (percentage) => {
    if (percentage > 75) return "#03CD09";
    if (percentage > 50) return "#FFCB34";
    if (percentage > 25) return "#FF8800";
    return "#FF2443";
  };

  const { labels, data } = useMemo(() => {
    const today = dayjs();
    let labels = [];
    let data = Array(9).fill(0); // Assuming 9 topics

    if (selectedDate === "W") {
      labels = Array.from({ length: 7 }, (_, i) =>
        today.startOf("week").add(i, "day").format("DD")
      );

      labels.forEach((_, i) => {
        data[i % 9] = Math.floor(Math.random() * 10); // Random attendance data
      });
    } else if (selectedDate === "M") {
      const daysInMonth = today.daysInMonth();
      labels = Array.from({ length: daysInMonth }, (_, i) =>
        (i + 1).toString()
      );

      labels.forEach((_, i) => {
        data[i % 9] = Math.floor(Math.random() * 10); // Random attendance data
      });
    }

    return { labels, data };
  }, [selectedDate]);

  const chartData = {
    labels,
    datasets: [
      {
        label: "Topic Attendance",
        data: data,
        backgroundColor: "#FF8800",
        borderWidth: 0,
        barPercentage: 0.2,
        borderRadius: 5,
      },
    ],
  };
  //second bar
  const topicLabels = sampleGraph.map((item) => ` ${item.topic_no}`);
  const scoreData = sampleGraph.map((item) => item.percentage);

  const learningSectionRightData = {
    labels: topicLabels,
    datasets: [
      {
        label: "Topic Scores",
        data: scoreData,
        backgroundColor: "#FFBE00",
        borderWidth: 0,
        barPercentage: 0.5,
        categoryPercentage: 0.8,
        borderRadius: 5,
      },
    ],
  };

  const dailyLineLabels = dailyDrillData.map((item) =>
    item["x-axis"].substring(8, 10)
  ); // Extracts DD from YYYY-MM-DD
  const dailyLineScores = dailyDrillData.map((item) => item["y-axis"]);
  const ddCorrectScores = dailyDrillData.map((item) => item["correct"]); // Array of correct scores
  const ddTimeInSeconds = dailyDrillData.map((item) => item["time_sec"]);

  const lineChartData = {
    labels: dailyLineLabels,
    datasets: [
      {
        label: "Today Scores",
        data: dailyLineScores,
        borderColor: "#595BDB",
        backgroundColor: "rgba(89, 91, 219, 0.2)",
        fill: true,
        tension: 0.3,
        borderWidth: 2,
        pointBackgroundColor: "#595BDB",
        pointBorderColor: "#fff",
        pointBorderWidth: 2,
        pointRadius: 5,
      },
    ],
  };

  const weeklyLineLabels = weeklyWorkoutData.map((item) =>
    item["x-axis"].substring(8, 10)
  ); // Extracts DD from YYYY-MM-DD
  const weeklyLineScores = weeklyWorkoutData.map((item) => item["y-axis"]);
  const wwCorrectScores = weeklyWorkoutData.map((item) => item["correct"]); // Array of correct scores
  const wwTimeInSeconds = weeklyWorkoutData.map((item) => item["time_sec"]);

  const weeklyLineChart = {
    labels: weeklyLineLabels,
    datasets: [
      {
        label: "Weekly Scores",
        data: weeklyLineScores,
        borderColor: "#29bcd6",
        backgroundColor: "#7adef0",
        fill: true,
        tension: 0.3,
        borderWidth: 2,
        pointBackgroundColor: "#29bcd6",
        pointBorderColor: "#fff",
        pointBorderWidth: 2,
        pointRadius: 5,
      },
    ],
  };

  async function mainAnalytics() {
    await fetch(`${baseUrl}/performance/analytics/main`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userid,
        org_id: orgid,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setMainData(data.data[0]);
        } else {
          setMainData({});
        }
      });
  }

  async function dailyDrillGraph(type) {
    await fetch(`${baseUrl}/analytics/scores/daily`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userid,
        filter_type: type,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setDailyDrillData(data.data);
        } else {
          setDailyDrillData([]);
        }
      });
  }
  async function weeklyWorkoutGraph(type) {
    await fetch(`${baseUrl}/analytics/scores/weekly`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userid,
        filter_type: type,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setWeeklyWorkoutData(data.data);
        } else {
          setWeeklyWorkoutData([]);
        }
      });
  }

  useEffect(() => {
    if (key) {
      mainAnalytics();
      dailyDrillGraph("week");
      weeklyWorkoutGraph("week");
    }
  }, [key]);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerText}>
          <p>Performance</p>
        </div>
      </div>
      <div className={style.performance}>
          
            <div className={style.moduleSection}>
            <div className={style.moduleHeder}>
                <p>Modules</p>
            </div>
            <div className={style.numberBarSection}>
                <div className={style.numberBar}>
                  {mainData.Modules&&mainData.Modules.length>0&&mainData.Modules.map((module, index) => (
                    <div
                      key={module.module_id}
                      className={
                        selectedModule === index
                          ? style.selectedNumber
                          : style.number
                      }
                      onClick={() => setSelectedModule(index)}
                    >
                      {index + 1}
                    </div>
                  ))}
                </div>
              </div>
              <div className={style.moduleSubHeder}>
              {mainData.Modules?.length > 0 && mainData.Modules[selectedModule] ? (
                    <p>
                      Module progress:{" "}
                      <span>{mainData.Modules[selectedModule]?.module_name}</span>
                    </p>
                  ) : (
                    <p>Module progress: <span>No Module Selected</span></p>
                  )}
            </div>
            <div className={style.progressBarSection}>
            <div
              className={style.progressBar}
              style={{
                
                backgroundColor: "#8E88903D",
                borderRadius: "2rem",
              }}
            >
              <div
                style={{
                  width: `${75}%`,
                  backgroundColor: "#595BDB",
                  height: "12px",
                  transition: "width 0.5s ease-in-out",
                  borderRadius: "1rem",
                }}
              ></div>
            </div>
            <div className={style.progressBarCount}>
            <p>75%</p>
            </div>
          </div>
            <div className={style.roundGraphSection}>
              {sampleGraph.length>0&&sampleGraph.map((data,i)=>{
                const color = getColorBasedOnPercentage(data.percentage);
                const chartData = {
                  labels: ["Achieved", "Remaining"],
                  datasets: [
                    {
                      data: [data.percentage, 100 - data.percentage], // Fill based on percentage
                      backgroundColor: [color, "#EAEAEA"],   
                      borderWidth: 0,
                    },
                  ],
                };
      
                const options = {
                  cutout: '80%', 
                  plugins: {
                    legend: {
                      display: false, 
                    },
                    tooltip: {
                      enabled: false, 
                    },
                  },
                };
                return(
                <div key={i} className={style.roundGraphCard}>
                <div className={style.roundGraphCardHeder}>
                  <p>Topic {data.topic_no}</p>
                </div>
                <div className={style.roundGraph}>
                <Doughnut data={chartData} options={options} />
                <div className={style.roundGraphData}>
                  <p>{data.mark}</p>
                </div>
                </div>
  
                <div className={style.roundGraphCardBottom}>
                  <p>{data.percentage}%</p>
                </div>
                </div>
                )
              })}
            </div>
        </div>

        <div className={style.learningSection}>
        <div className={style.learningSectionLeft}>
          <div className={style.learningHeader}>
            <p>Learning Pace</p>
          </div>
          <div className={style.learningSubHeader}>
          <div className={style.learningSubHeaderLeft}>
           <div className={`${style.subHeaderCard} ${selectedDate==="W"?style.selectedSubHeaderCard:""}`}
           onClick={() => setSelectedDate("W")}>
            <p>W</p>
           </div>
           <div className={`${style.subHeaderCard} ${selectedDate==="M"?style.selectedSubHeaderCard:""}`}
           onClick={() => setSelectedDate("M")}>
            <p>M</p>
           </div>
           <div className={`${style.subHeaderCard} ${selectedDate==="Y"?style.selectedSubHeaderCard:""}`}
           onClick={() => setSelectedDate("Y")}>
            <p>Y</p>
           </div>
           </div>
           <div className={style.learningSubHeaderRight}>
           <select>
            <option selected>Module 1</option>
           </select>
           </div>
          </div>
          <div className={style.barGraph}>
          <Bar
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  return `Topic ${context.dataIndex + 1}: ${context.raw}`;
                },
              },
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: `Day (${dayjs().format('MMM')})`,
              },
              grid: {
                display: false, // Remove grid lines from x-axis
              },
              beginAtZero: false,
              ticks: {
                stepSize: 1,
              },
            },
            y: {
              title: {
                display: true,
                text: 'Topics',
              },
              beginAtZero: true,
              ticks: {
                stepSize: 1,
                max: 9,
              },
            },
          },
        }}
      />
          </div>
        </div>
        <div className={style.learningSectionRight}>
        <div className={style.learningHeader}>
            <p>Learning Level</p>
          </div>
          <div className={style.learningSubHeader}>
          <div className={style.levelHeader}>
          <div className={style.levelHeaderCard}>
            <span></span>
            <h4>Current Score</h4>
            <p>(The mark scored in the last attempt)
            </p>
          </div>
          <div className={style.levelHeaderCard}>
            <span></span>
            <h4>Baseline score</h4>
            <p>(The mark scored in the first attempt)
            </p>
          </div>
          </div>
           <div className={style.learningSubHeaderRight}>
           <select>
            <option selected>Module 1</option>
           </select>
           </div>
          </div>
          <div className={style.barGraph}>
          <Bar
                data={learningSectionRightData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          return `Topic ${context.dataIndex + 1}: ${context.raw}%`;
                        },
                      },
                    },
                  },
                  scales: {
                    x: {
                      title: {
                        display: true,
                        text: 'Topics',
                      },
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      title: {
                        display: true,
                        text: 'Score (%)',
                      },
                      beginAtZero: true,
                      ticks: {
                        stepSize: 20, 
                      },
                    },
                  },
                }}
              />
    
          </div>
        </div>
        </div>
        <div className={style.assessmentSection}>
        <div className={style.assessmentSectionLeft}>
        <div className={style.learningHeader}>
            <p>Daily drill</p>
          </div>
          <div className={style.learningSubHeader}>
          <div className={style.learningSubHeaderLeft}>
           <div className={`${style.subHeaderCard} ${dateForDD==="week"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleDDDate("week")}>
            <p>W</p>
           </div>
           <div className={`${style.subHeaderCard} ${dateForDD==="month"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleDDDate("month")}>
            <p>M</p>
           </div>
           <div className={`${style.subHeaderCard} ${dateForDD==="year"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleDDDate("year")}>
            <p>Y</p>
           </div>
           </div>
           <div className={style.learningSubHeaderRight}>
            <div className={style.assessmentScore}>
              <p>Today’s <br/>
              Score</p>
              <h4>12</h4>
            </div>
           </div>
          </div>
          <div className={style.lineGraph}>
          <Line
              data={lineChartData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        const index = context.dataIndex;
                        const score = ddCorrectScores[index];
                        const timeSec = ddTimeInSeconds[index];
                        const timeMin = Math.floor(timeSec / 60); // Convert time_sec to minutes
                        const remainingSec = timeSec % 60; // Get the remaining seconds if any

                        // Format time display
                        const timeDisplay = timeMin > 0 
                          ? `${timeMin} mins ${remainingSec} secs`
                          : `${timeSec} secs`;

                        return `You scored ${score} in ${timeDisplay}`;
                      },
                    },
                  },
                },
                scales: {
                  x: {
                    type: 'category', 
                    title: {
                      display: true,
                      text: `Day `, 
                    },
                    grid: {
                      display: false,
                    },
                    beginAtZero: false,
                    offset:true,
                    ticks: {
                      callback: function(value, index) {
                        return dailyLineLabels[index]; 
                      },
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: 'Score (%)',
                    },
                    grid: {
                      display: false,
                    },
                    beginAtZero: true,
                    min: 0,
                    max: 100,
                    ticks: {
                      stepSize: 20,
                    },
                  },
                },
              }}
            />

          </div>
        </div>
        <div className={style.assessmentSectionRight}>
        <div className={style.learningHeader}>
            <p>Weekly Workout</p>
          </div>
          <div className={style.learningSubHeader}>
          <div className={style.learningSubHeaderLeft}>
           <div className={`${style.subHeaderCard} ${dateForWW==="week"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleWWDate("week")}>
            <p>W</p>
           </div>
           <div className={`${style.subHeaderCard} ${dateForWW==="month"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleWWDate("month")}>
            <p>M</p>
           </div>
           <div className={`${style.subHeaderCard} ${dateForWW==="year"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleWWDate("year")}>
            <p>Y</p>
           </div>
           </div>
           <div className={style.learningSubHeaderRight}>
            <div className={style.assessmentScore} style={{backgroundColor:"#00DFC7"}}>
              <p>Week's <br/>
              Score</p>
              <h4>12</h4>
            </div>
           </div>
          </div>

          <div className={style.lineGraph}>
          <Line
              data={weeklyLineChart}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        const index = context.dataIndex;
                        const score = wwCorrectScores[index];
                        const timeSec = wwTimeInSeconds[index];
                        const timeMin = Math.floor(timeSec / 60); // Convert time_sec to minutes
                        const remainingSec = timeSec % 60; // Get the remaining seconds if any

                        // Format time display
                        const timeDisplay = timeMin > 0 
                          ? `${timeMin} mins ${remainingSec} secs`
                          : `${timeSec} secs`;

                        return `You scored ${score} in ${timeDisplay}`;
                      },
                    },
                  },
                },
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: `Day `, // Dynamic month abbreviation
                    },
                    grid: {
                      display: false,
                    },
                    offset:true,
                  },
                  y: {
                    title: {
                      display: true,
                      text: 'Score (%)',
                    },
                    grid: {
                      display: false,
                    },
                    beginAtZero: true,
                    min: 0,
                    max: 100,
                    ticks: {
                      stepSize: 20,
                    },
                  },
                },
              }}
            />
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Performance;
