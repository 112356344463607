import React,{useState,useEffect} from "react";
import style from "./Subscription.module.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { baseUrl } from "../Url";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";

const Subscription = () => {

    const adminStatus = localStorage.getItem("adminStatus");
    const key = JSON.parse(localStorage.getItem("access-token"));
    const userId = localStorage.getItem("adminUserId");

    const { profile } = useSelector((state) => state.profileData);

    const [userData, setUserData] = useState([]);
    const [orgList, setOrgList] = useState([]);
    const [selectedOrgId, setSelectedOrgId] = useState("");
    const [selectedCLubId, setSelectedClubId] = useState("");
    const [clubList, setClubList] = useState([]);
    const [clubReadOnly, setClubReadOnly] = useState(true);
    const [userReadOnly, setUserReadOnly] = useState(true);
    const [subscriptionData, setSubscriptionData] = useState({});
    const [tableShow,setTableShow]=useState(false)

    async function OrgGet() {
        await fetch(`${baseUrl}/org/get/admin/per_users`, {
          method: "POST",
          body: JSON.stringify({
            user_id: userId,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${key}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            setOrgList(data.data);
          });
      }
      async function ClubGet(OrgId) {
        await fetch(`${baseUrl}/club/list`, {
          method: "POST",
          body: JSON.stringify({
            OrganizationID: OrgId,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${key}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            setClubList(data.data);
          });
      }

      async function userGet(id) {
        await fetch(`${baseUrl}/users/subscribed`, {
          method: "POST",
          body: JSON.stringify({
            org_id: selectedOrgId,
            club_id:id
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${key}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            setUserData(data.data);
          });
      }
      async function subscriptionDetails(id) {
        await fetch(`${baseUrl}/subscribe/user/data`, {
          method: "POST",
          body: JSON.stringify({
            user_id:id
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${key}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            setSubscriptionData(data.data);
            setTableShow(true)
          });
      }

      useEffect(()=>{
        if(adminStatus === true || adminStatus === "true"){
            OrgGet()
        }
        else{
            const orgid = profile?.org_id;
            if(orgid!==undefined||orgid!==""){
                setSelectedOrgId(orgid)
                setClubReadOnly(false)
                ClubGet(orgid)
            }
        }
      },[adminStatus])

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerText}>
          <p>Subscription</p>
        </div>
        <div className={style.headButton}></div>
      </div>
      <div className={style.secondHead}>
        <div className={style.select_section}>
          <div className={style.selectLeft}>
            {adminStatus === true || adminStatus === "true" ? (
              <FormControl sx={{ width: "50%", margin: "0 0.25rem" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Organizations
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Organizations"
                  value={selectedOrgId}
                  onChange={(e) => {
                    const OrgID = e.target.value
                    if(OrgID!==""){
                        setSelectedOrgId(OrgID)
                        ClubGet(OrgID)
                        setClubReadOnly(false)
                    }
                    
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, // Adjust the maximum height as needed
                      },
                    },
                  }}
                >
                  {orgList && orgList.length > 0 ? (
                    orgList.map((org) => (
                      <MenuItem key={org.org_id} value={org.org_id}>
                        {org.org_name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No organizations available</MenuItem>
                  )}
                </Select>
              </FormControl>
            ) : (
              ""
            )}
            <FormControl
              sx={{ width: "50%", margin: "0 0.25rem", zIndex: "0" }}
            >
              <InputLabel id="demo-simple-select-label">
                Select Club/Batch
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Clubs/Batchs"
                disabled={clubReadOnly}
                value={selectedCLubId}
                onChange={(e) => {
                  const ClubID = e.target.value;
                  if (ClubID !== "") {
                    setSelectedClubId(ClubID);
                    userGet(ClubID)
                    setUserReadOnly(false)
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300, 
                    },
                  },
                }}
              >
                {clubList && clubList.length > 0 ? (
                  clubList.map((club) => (
                    <MenuItem value={club.ClubID} key={club.ClubID}>
                      {club.ClubName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Clubs/Batchs available</MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl
              sx={{ width: "50%", margin: "0 0.25rem", zIndex: "0" }}
            >
              <InputLabel id="demo-simple-select-label">
                Select user
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select user"
                disabled={userReadOnly}
                onChange={(e) => {
                  const userID = e.target.value;
                  if (userID !== "") {
                    subscriptionDetails(userID)
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300, 
                    },
                  },
                }}
              >
                {userData && userData.length > 0 ? (
                  userData.map((user) => (
                    <MenuItem value={user._id.$oid} key={user._id.$oid}>
                      {user.Username}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No user available</MenuItem>
                )}
              </Select>
            </FormControl>
          </div>

        </div>
      </div>
      <div className={style.body}>
      <div className={style.Table}>
        {tableShow && subscriptionData?
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      key="name"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "1px solid  #dedede",
                        borderLeft: "1px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                      User Name
                    </TableCell>
                    <TableCell
                      key="Start"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "1px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                      Start date
                    </TableCell>
                    <TableCell
                      key="endDate"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "1px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                     End date
                    </TableCell>
                    <TableCell
                      key="amount"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "1px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      key="courseName"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "1px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                     Course name
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      key="username"
                      align="left"
                      style={{
                        border: "1px solid #e8e4ef",
                        fontSize: "1rem",
                      }}
                    >
                      {subscriptionData.Username}
                    </TableCell>
                    <TableCell
                      key="startDate"
                      align="left"
                      style={{
                        border: "1px solid #e8e4ef",
                        fontSize: "1rem",
                      }}
                    >
                      {new Date(subscriptionData.subscription_start_date.$date).toLocaleDateString()}
                    </TableCell>
                    <TableCell
                      key="endDate"
                      align="left"
                      style={{
                        border: "1px solid #e8e4ef",
                        fontSize: "1rem",
                      }}
                    >
                      {new Date(subscriptionData.subscription_end_date.$date).toLocaleDateString()}
                    </TableCell>
                    <TableCell
                      key="amount"
                      align="left"
                      style={{
                        border: "1px solid #e8e4ef",
                        fontSize: "1rem",
                      }}
                    >
                      {subscriptionData.amount_paid}
                    </TableCell>
                    <TableCell
                      key="courseName"
                      align="left"
                      style={{
                        border: "1px solid #e8e4ef",
                        fontSize: "1rem",
                      }}
                    >
                      {subscriptionData.product_name}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              :null}
            </div>
      </div>
    </div>
  );
};

export default Subscription;
