import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import Layout from "./Components/Layout";
import User from "./Components/UserManagement/User";
import Profile from "./Components/Profile/Profile";
import Learning from "./Components/Learning/Learning";
import Login from "./Components/Login/Login";
import Register from "./Components/Register/Register";
import Syllabus from "./Components/Syllabus/Syllabus";
import Lesson from "./Components/Lesson/Lesson";
import Organization from "./Components/Organization/Organization";
import Club from "./Components/Club/Club";
import Member from "./Components/Members/Member";
import LessonMapping from "./Components/LessonMapping/LessonMapping";
import SingleLessonMapping from "./Components/SingleLessonMapping/SingleLessonMapping";
import UserLesson from "./Components/UserLesson/UserLesson";
import SingleLessonEdit from "./Components/SingleLessonEdit/SingleLessonEdit";
import LessonAdminCreate from "./Components/LessonAdminCreate/LessonAdminCreate";
import CLubLesson from "./Components/ClubLesson/CLubLesson";
import ExistingUser from "./Components/ExistingUser/ExistingUser";
import Pricing from "./Components/Pricing/Pricing"
import Billing from "./Components/Billing/Billing"
import Invoice from "./Components/InvoiceCreation/Invoice"
import PricingAction from "./Components/PricingActions/PricingAction"
import { useState } from "react";
import Asessments from "./Components/Asessments/Asessments";
import AsessmentsCreation from "./Components/AsessmentsCreation/AsessmentsCreation";
import Exams from "./Components/Exams/Exams";
import ExamsCreation from "./Components/ExamsCreation/ExamsCreation"
import RewardSetting from "./Components/RewardSettings/RewardSetting";
import QuizListing from "./Components/QuizListing/QuizListing";
import QuizEdit from "./Components/QuizEdit/QuizEdit";
import QuizCreation from "./Components/QuizCreation/QuizCreation";
import Gallery from "./Components/Gallery/Gallery";
import Events from "./Components/Events/Events";
import EventCreation from "./Components/EventCreation/EventCreation";
import EventDetails from "./Components/EventDetails/EventDetails";
import EventUpdate from "./Components/EventUpdate/EventUpdate";
import Subscription from "./Components/Subscription/Subscription";
import Performance from "./Components/Performance/Performance";





function App() {

  const [logoutValue, setLogoutValue] = useState(null);


  return (


    <div className="App">
      <Router>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path='/user' element={<User logoutValue={logoutValue} />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/learning' element={<Learning logoutValue={logoutValue} />} />
            <Route path='/syllabus' element={<Syllabus />} />
            <Route path='/lesson' element={<Lesson />} />
            <Route path='/organization' element={<Organization />} />
            <Route path='/club' element={<Club />} />
            <Route path='/members' element={<Member logoutValue={logoutValue} />} />
            <Route path='/lesson/mapping' element={<LessonMapping />} />
            <Route path='/single/lesson/mapping' element={<SingleLessonMapping />} />
            <Route path='/user/lesson' element={<UserLesson />} />
            <Route path='/lesson/edit' element={<SingleLessonEdit />} />
            <Route path='/lesson/create/admin' element={<LessonAdminCreate />} />
            <Route path='/club/lesson' element={<CLubLesson />} />
            <Route path='/user/mapping' element={<ExistingUser />} />
            <Route path='/pricing' element={<Pricing />} />
            <Route path='/pricing/actions' element={<PricingAction />} />
            <Route path='/billing' element={<Billing />} />
            <Route path='/invoice' element={<Invoice />} />
            <Route path='/asessments' element={<Asessments />} />
            <Route path='/asessments/creation' element={<AsessmentsCreation />} />
            <Route path='/exams' element={<Exams />} />
            <Route path='/exams/creation' element={<ExamsCreation />} />
            <Route path='/reward' element={<RewardSetting />} />
            <Route path='/quiz' element={<QuizListing />} />
            <Route path='/quiz/edit' element={<QuizEdit />} />
            <Route path='/quiz/create' element={<QuizCreation />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/events' element={<Events />} />
            <Route path='/event/creation' element={<EventCreation />} />
            <Route path='/event/details' element={<EventDetails />} />
            <Route path='/event/update' element={<EventUpdate />} />
            <Route path='/subscription' element={<Subscription />} />
            <Route path='/performance' element={<Performance />} />
          </Route>

        </Routes>
      </Router>
    </div>
  );
}

export default App;
